window.fetchSearchTest = (formData) => {
  let terms = ''

  const elements = [...formData.elements]

  elements.forEach( (element, index) => {
    switch (element.name) {
      case 'terms':
      terms = element.value
      break
    }
  })

  fetch('/api/search?&template=search&terms=' + terms)
  .then(function(response) {
    return response.text();
  })
  .then(function (text) {
    document.getElementById('js-put-it-here').innerHTML = text;
  })

}
