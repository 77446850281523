import debounce from 'lodash/debounce'
import defer from 'lodash/defer'
import find from 'lodash/find'

const EXPAND_ATTR = `data-js-expand`
const EXPAND_SELECTOR = `[data-js-expand]`
const EXPAND_CONTROL_SELECTOR = `[data-js-expand-control]`

const expands = [...document.querySelectorAll(EXPAND_SELECTOR)]

// const calculateHeights = () => expands.map(expand => {
//   let maxHeight
//   const active = expand.getAttribute('aria-expanded') === 'true'

//   expand.style.maxHeight = 'none'
//   maxHeight = expand.offsetHeight

//   defer(() => {
//     if (active)
//       expand.style.maxHeight = `${ maxHeight }px`
//     else
//       expand.style.maxHeight = null
//   })

//   return { expand, maxHeight }
// })

// let heightCache = calculateHeights()

expands.forEach(expand => {
  const control = expand.querySelector(EXPAND_CONTROL_SELECTOR)
  // const maxHeight = find(heightCache, { expand }).maxHeight

  let active = false

  expand.setAttribute('aria-expanded', active)

  control && control.addEventListener('click', event => {
    active = !active

    control.setAttribute('aria-pressed', active)
    expand.setAttribute('aria-expanded', active)

    // if (active)
    //   expand.style.maxHeight = `${ maxHeight }px`
    // else
    //   expand.style.maxHeight = null
  })
})

// const recalculateHeights = debounce(() => {
//   heightCache = calculateHeights()
// }, 150)

// window.addEventListener('resize', recalculateHeights)
