// node_modules
import Promise from 'promise-polyfill'
import 'objectFitPolyfill'
import 'picturefill'
import 'whatwg-fetch'

import './scripts/smooth-scroll'

if(!('Promise' in window)) {
  window.Promise = Promise
}

// Local modules
// import './scripts/form'

// Debug stuff
if (window.APP_CONFIG.devMode) {
  const store = window.localStorage

  // Debug grid toggle
  function addToggle(inputLabel, toggleElements, position = 'left') {
    const label = document.createElement('label')
    const input = document.createElement('input')

    const inputChecked = store.getItem(`debug${ inputLabel }`) === 'true' || input.checked

    const text = document.createTextNode(` ${ inputLabel } ${ inputChecked ? 'on' : 'off' }`)

    Object.assign(label.style, {
      bottom: '0',
      position: 'fixed',
      [position]: '0.5rem',
      zIndex: 9999,
    })

    input.checked = inputChecked
    input.type = 'checkbox'

    if (inputChecked) {
      toggleElements.forEach(node => {
        node.parentNode.style.display = 'block'
      })
    }

    input.addEventListener('change', () => {
      store.setItem(`debug${ inputLabel }`, input.checked)

      toggleElements.forEach(node => {
        // assume parent = .u-debug
        node.parentNode.style.display = input.checked ? 'block' : 'none'
      })
      text.textContent = ` ${ inputLabel } ${ input.checked ? 'on' : 'off' }`
    })

    label.appendChild(input)
    label.appendChild(text)
    document.body.appendChild(label)
  }

  const grid = [...document.querySelectorAll('.u-debug-grid')]
  const lists = [...document.querySelectorAll('.u-debug-list')]

  addToggle('Grid', grid, 'right')
  addToggle('Components', lists, 'left')

  // Debug content-block toggle
  lists.forEach(list => {
    let active = false

    list.addEventListener('click', event => {
      active = !active

      list.setAttribute('aria-expanded', active)
    })
  })
}
