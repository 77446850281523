import find from 'lodash/find'
import filter from 'lodash/filter'
import isFunction from 'lodash/isFunction'
import unset from 'lodash/unset'

import Loader from '../loader/loader'
import QueryCache from '../../_app/scripts/query-cache'
import { init as carouselInit } from '../variant/variant-carousel'

const AVAILABLE_ATTR = `data-js-variant-available`
const AVAILABLE_MESSAGE_ATTR = `data-js-variant-available-message`
const CONTROLS_ATTR = `data-js-variant-control`
const OPTIONS_ATTR = `data-js-variant`
const VARIANT_SELECTOR_ATTR = `data-js-variant-selector`

const BUTTON_SELECTOR = `[data-js-variant-purchasable="button"]`
const CONTROLS_SELECTOR = `[data-js-variant-control]`
const MESSAGE_INSTOCK_SELECTOR = `[data-js-variant-purchasable="true"]`
const MESSAGE_OUTOFSTOCK_SELECTOR = `[data-js-variant-purchasable="false"]`
const OPTIONS_SELECTOR = `#purchasableId > [data-js-variant]`
const SELECT_SELECTOR = `#purchasableId`
const VARIANT_SELECTOR_SELECTOR = `[data-js-variant-selector]`

const variantSelectors = [...document.querySelectorAll(VARIANT_SELECTOR_SELECTOR)]

variantSelectors.forEach(variantSelector => {
  clickLeaseInfo();
  const config = JSON.parse(variantSelector.getAttribute(VARIANT_SELECTOR_ATTR))

  const purchasablesProxy = document.querySelector(SELECT_SELECTOR)
  const purchasableButton = document.querySelector(BUTTON_SELECTOR)
  const purchasableInstockMessages = document.querySelectorAll(MESSAGE_INSTOCK_SELECTOR)
  const purchasableOutofstockMessages = document.querySelectorAll(MESSAGE_OUTOFSTOCK_SELECTOR)

  const loader = new Loader()
  const updateView = (target, html, callback) => {
    target.innerHTML = html
    isFunction(callback) && callback()
  }

  // const loader = new Loader()
  const loadComponent = (target, url, callback) => {
    // loader.attach(target)

    if (QueryCache.has(url)) {
      // loader.detach(target)
      updateView(target, QueryCache.get(url), callback)
      return
    }

    return fetch(url)
    .then(response => response.text())
    .then(text => {
      QueryCache.set(url, text)
      // loader.detach(target)
      updateView(target, text, callback)
    })
  }

  /**
   * purchasables is an array filled with the data-js-variant JSON object, merged
   * with an object containing the actual DOM node from the select proxy
   */
  const purchasables = [...document.querySelectorAll(OPTIONS_SELECTOR)]
    .map(option => Object.assign(
      JSON.parse(option.getAttribute(OPTIONS_ATTR)),
      { node: option },
    ))

  const purchasableControls = [...document.querySelectorAll(CONTROLS_SELECTOR)]

  // We need inStock in the purchasableOptions for proper variant matching. It needs
  // to be in stock.
  let purchasableOptions = {}

  purchasableControls.forEach(control => {
    const name = control.getAttribute(CONTROLS_ATTR)
    const options = [...document.querySelectorAll(`[name="${ name }"]`)]

    const defaultSelectedOption = options.filter(option => option.checked)[0]

    purchasableOptions = Object.assign(
      purchasableOptions,
      { [name]: defaultSelectedOption ? isNaN(String(defaultSelectedOption.value)) ? defaultSelectedOption.value : String(defaultSelectedOption.value) : null }
    )

    options.forEach(option => option.addEventListener('change', event => {
      purchasableOptions[name] = String(option.value);
      selectPurchasable(purchasableOptions);
      updateAvailablePurchasables(purchasableOptions);
    }))
  })

  function selectPurchasable(purchasableOptions) {
    console.log(purchasables);
    console.log(purchasableOptions);
    const purchasable = find(purchasables, purchasableOptions)
    const possiblePurchasable = find(purchasables, {
      variantColor: purchasableOptions.variantColor
    })
    console.log(purchasable);
    console.log(possiblePurchasable);

    if (purchasable) {
      updatePrice(purchasable.sku, purchasable.mandatoryProduct)
      updateCarousel(purchasable.sku);
      if (purchasable.mandatoryProduct != "") {
        updateMandatoryProduct(purchasable.mandatoryProduct)
      }
      updateAMForm(purchasable)
    } else if (possiblePurchasable) {
      updatePrice(possiblePurchasable.sku)
      updateCarousel(possiblePurchasable.sku)
      updateAMForm(possiblePurchasable)
    }

    if (purchasable && purchasable.inStock) {
      purchasable.node.selected = true
      purchasableButton.disabled = false
      purchasableInstockMessages.forEach(function(purchasableInstockMessage) {
        purchasableInstockMessage.hidden = false
      });
      purchasableOutofstockMessages.forEach(function(purchasableOutofstockMessage) {
        purchasableOutofstockMessage.hidden = true
      });
    // } else if (possiblePurchasable && possiblePurchasable.inStock) {
    //   possiblePurchasable.node.selected = true
    //   purchasableButton.disabled = false
    //   purchasableInstockMessages.forEach(function(purchasableInstockMessage) {
    //     purchasableInstockMessage.hidden = false
    //   });
    //   purchasableOutofstockMessages.forEach(function(purchasableOutofstockMessage) {
    //     purchasableOutofstockMessage.hidden = true
    //   });
    } else {
      purchasablesProxy.selectedIndex = -1
      purchasableButton.disabled = true
      purchasableInstockMessages.forEach(function(purchasableInstockMessage) {
        purchasableInstockMessage.hidden = true
      });
      purchasableOutofstockMessages.forEach(function(purchasableOutofstockMessage) {
        purchasableOutofstockMessage.hidden = false
      });
    }
  }

  function updateAvailablePurchasables(purchasableOptions) {
    purchasableControls.forEach(control => {
      const name = control.getAttribute(CONTROLS_ATTR)
      const options = [...document.querySelectorAll(`[name="${ name }"]`)]
      const labels = [...document.querySelectorAll(`[name="${ name }"] + label`)]

      const possibleOptions = Object.assign({}, purchasableOptions)

      unset(possibleOptions, name)

      const possiblePurchasables = filter(purchasables, possibleOptions)

      options.forEach((option, index) => {
        const optionIsAvailable = possiblePurchasables.some(purchasable => purchasable[name] === option.value)

        option.setAttribute(AVAILABLE_ATTR, optionIsAvailable)
        optionIsAvailable
          ? labels[index].removeAttribute(AVAILABLE_MESSAGE_ATTR)
          : labels[index].setAttribute(AVAILABLE_MESSAGE_ATTR, (purchasableOutofstockMessages.textContent || '').trim())
      })
    })
  }

  let lastLoadedCarouselSku = null

  function updateCarousel(sku) {
    if (sku !== lastLoadedCarouselSku) {
      const target = document.querySelector(config.carouselContainer)
      const url = config.carouselUrl + `&sku=${ sku }`

      loader.attach(target)

      loadComponent(target, url, () => {
        carouselInit(target)
      })

      lastLoadedCarouselSku = sku
    }
  }

  function updatePrice(sku, mandatoryProduct) {
    const target = document.querySelector(config.priceContainer)
    const url = config.priceUrl + `&sku=${ sku }&mandatoryProduct=${ mandatoryProduct }`

    loadComponent(target, url)
  }

  function updateMandatoryProduct(mandatoryProduct) {
    const mandatoryProductElement = document.querySelector(`#mandatoryProduct`);
    mandatoryProductElement.value = mandatoryProduct;
  }

  function updateAMForm(variant) {
    var product = document.querySelector(".o-sold-out-form input[name=product][type=hidden]");
    var productSku = document.querySelector(".o-sold-out-form input[name=productSku][type=hidden]");
    var productGuid = document.querySelector(".o-sold-out-form input[name=productGuid][type=hidden]");
    var productTitle = document.querySelector(".o-sold-out-form input[name=productTitle][type=hidden]");

    if (product) {
      product.value = variant.variantCode;
    }
    if (productSku) {
      productSku.value = variant.sku;
    }
    if (productGuid) {
      productGuid.value = variant.variantGuid;
    }
    if (productTitle) {
      productTitle.value = variant.variantTitle;
    }
  }

  function clickLeaseInfo() {
    var leaseButton = document.getElementById("lease-info-button");
    if (leaseButton) {
      leaseButton.addEventListener("click", function(event) {
        event.preventDefault();
        var link = document.getElementById('lease-form-tab');
        link.click();
      });
    }
  }
})
