// Same address checkbox toggles the extra input fields needed for
// the invoice address
const SAME_ADDRESS_SELECTOR = `[name="billingAddressSameAsShipping"]`

function init(root = document) {
  const inputs = [...root.querySelectorAll(SAME_ADDRESS_SELECTOR)]

  inputs.forEach(input => {
    const target = root.querySelector(input.getAttribute('aria-controls'))

    let active = !input.checked

    input.addEventListener('click', event => {
      active = !input.checked

      target.setAttribute('aria-expanded', active)
    })
  })
}

init()
