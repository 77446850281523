import debounce from 'lodash/debounce'

import { init as initDropdowns } from '../dropdown/dropdown'
import { init as initPagination } from '../pagination/pagination'

import Loader from '../loader/loader'
import QueryCache from '../../_app/scripts/query-cache'

/**
 * Filters
 *
 * @param {Url} data-js-filters URL to request to.
 * @param {Number} data-js-filters-vendit-type Vendit representation type
 *
 * Filters looks for a data-js-filters attribute on it's root node. This attribute
 * should contain the URL to request new content based on applied filters. Filters
 * loops through every input element as a descendant of it's root node and applies
 * results onChange.
 *
 * If any of these inputs has a data-js-filters-vendit-type, the filters module
 * prepares the request in accordance with the custom filter API for Vendit specs.
 *
 * @example
 * <div
 *  ...
 *  data-js-filters="/api/products"
 * >
 *  ...
 *  <input name="Wielmaat" value="23" data-js-filters-vendit-type="2" />
 */
const ATTR_FILTERS = 'data-js-filters'
const ATTR_FILTERS_VENDIT_TYPE = 'data-js-filters-vendit-type'

const SELECTOR = `[${ ATTR_FILTERS }]`
const SELECTOR_VENDIT_TYPE = `[${ ATTR_FILTERS_VENDIT_TYPE }]`

const filtersLoader = new Loader()

/**
 * Updates a node's innerHTML
 * @param {Element} target the node on which the innerHTML will be set
 * @param {String} html the innerHTML used for the new node contents
 */
const updateView = (target, html) => {
  target.innerHTML = html
  init(target)
  initDropdowns(target)
  initPagination(target)
}

/**
 * Loads new HTML based on query on target
 * @param {Element} target the node which will be passed to updateView
 * @param {String} query the query built up through buildQuery
 */
const loadProducts = debounce((target, url) => {
  filtersLoader.attach(target)

  // We reuse the result from cache if the query has already been sent out
  if (QueryCache.has(url)) {
    filtersLoader.detach(target)
    updateView(target, QueryCache.get(url))
    return
  }

  return fetch(url)
    .then(response => response.text())
    .then(text => {
      // Whenever we have a new request, we add it's result to the queryCache
      QueryCache.set(url, text)
      filtersLoader.detach(target)
      updateView(target, text)
    })
}, 750)

/**
 * Builds and returns a query based on the inputs used in a form
 * @param {Element[]} inputs - An array of input elements
 */
const buildQuery = (inputs) => {
  const queryMap = {}

  // Build a queryMap
  inputs.forEach((input, index) => {
    let queryKey = index
    let queryValue = `${ input.name }=${ input.value }`

    if (input.type == 'hidden') {
      queryMap[queryKey] = queryValue
    }

    if (input.checked) {
      if (input.hasAttribute(ATTR_FILTERS_VENDIT_TYPE)) {
        const filterType = input.getAttribute(ATTR_FILTERS_VENDIT_TYPE)

        queryKey = input.name

        if (!(queryKey in queryMap)) {
          queryValue = [
            `filter-type[]=${ filterType }`,
            `filter-name[]=${ input.name }`,
            `filter-value[]=${ input.value }`
          ].join('&')
        } else {
          queryValue = `${ queryMap[queryKey] }|${ input.value }`
        }
      }

      queryMap[queryKey] = queryValue
    }
  })

  // Construct query
  return Object.keys(queryMap).map(key => queryMap[key]).join('&')
}

/**
 * Initiates filter logic for async requests.
 * @param {Element} root The root node onto which we want to override event handlers
 */
export function init(root = document) {
  const filterForms = [...root.querySelectorAll(SELECTOR)]

  filterForms.forEach(form => {
    const inputs = [...form.elements].filter(input => input.type !== 'fieldset')
    const target = document.querySelector(form.getAttribute('aria-controls'))
    const url = form.getAttribute(ATTR_FILTERS)

    function onInputChange(event) {
      loadProducts(target, `${ url }&${ buildQuery(inputs) }`)
    }

    inputs.forEach((input, index) => {
      input.addEventListener('change', onInputChange)
    })
  })
}

init()
