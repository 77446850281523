/**
 * Dropdown
 *
 * @param {-} data-js-dropdown - The dropdown root node
 * @param {-} data-js-dropdown-control - The button which toggles the dropdown
 * @param {-} data-js-dropdown-options - The list of possible options in the dropdown
 *
 * Dropdowns expect a data-js-dropdown attribute on its root node, a
 * data-js-dropdown-control on the button which opens the dropdown, and a
 * data-js-dropdown-options on the list of links, inputs, etc.
 *
 * @example
 * <div data-js-dropdown>
 *   <button data-js-dropdown-control>
 *     Open/Close
 *   </button>
 *
 *   <ul data-js-dropdown-options>
 *     ...
 *   </ul>
 * </div>
 */
import defer from 'lodash/defer'

const SELECTOR = `[data-js-dropdown]`
const SELECTOR_CONTROL = `[data-js-dropdown-control]`
const SELECTOR_OPTIONS = `[data-js-dropdown-options]`

/**
 * Initiates dropdown.
 * @param {Element} root The root node onto which we want to override event handlers
 */
export function init(root = document) {
  const dropdowns = [...root.querySelectorAll(SELECTOR)]

  dropdowns.forEach(dropdown => {
    const control = dropdown.querySelector(SELECTOR_CONTROL)
    const options = dropdown.querySelector(SELECTOR_OPTIONS)

    let active = false

    function toggleOnce(event) {
      // if event.target == the dropdown node, contains returns true
      if (!dropdown.contains(event.target)) {
        window.removeEventListener('click', toggleOnce)

        toggle()
      }
    }

    function toggle(event) {
      if (event) event.preventDefault()

      active = !active

      control.setAttribute('aria-pressed', active)
      options.setAttribute('aria-expanded', active)

      // When the user clicks outside the dropdown, the dropdown should close.
      // This makes sure that when the dropdown is opened, the listener for the
      // 'outside click' is removed again after the dropdown closes.
      defer(() => {
        if (active)
          window.addEventListener('click', toggleOnce)
        else
          window.removeEventListener('click', toggleOnce)
      })
    }

    control.addEventListener('click', toggle)
  })
}

init()
