import defer from 'lodash/defer'

import { notify } from '../site-notification/site-notification'

const ADD_TO_CART_ATTR = `data-js-add-to-cart`
const ADD_TO_CART_SELECTOR = `[${ADD_TO_CART_ATTR}]`

const CART_BADGE_ATTR = `data-js-cart-badge`
const CART_BADGE_SELECTOR = `[${CART_BADGE_ATTR}]`

function init(root = document) {
  const addToCartForms = [...root.querySelectorAll(ADD_TO_CART_SELECTOR)]
  const cartBadges = [...root.querySelectorAll(CART_BADGE_SELECTOR)]

  addToCartForms.forEach(form => {
    const url = form.querySelector(`[name="action"]`).value

    form.addEventListener('submit', event => {
      event.preventDefault();

      fetch(`/actions/${url}/`, {
        credentials: 'same-origin',
        headers: new Headers({ 'X-Requested-With': 'XMLHttpRequest', 'Accept': 'application/json'}),
        method: form.method,
        body: new FormData(form),
      }).then(response => response.json())
        .then(data => {
          console.log(data)

          if (data.success) {
            const isQuote = !!(new FormData(form)).get('options[quotePurchasableId]');
            notify(isQuote ? 'Product geselecteerd voor offerte.' : 'Product toegevoegd aan winkelmand.', 'success')
          } else {
            notify(`Product kon niet toegevoegd worden: "${data.error}"`, 'warning')
          }

          cartBadges.forEach(badge => {
            badge.textContent = data.cart.totalQty
            badge.classList.remove('o-badge--ping')

            defer(() => {
              badge.classList.add('o-badge--ping')
            })
          })
        })
    })
  })
}

init()
