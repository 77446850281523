export default {
  _cache: {},

  get(key) {
    return this._cache[key]
  },

  has(key) {
    return key in this._cache
  },

  set(key, value) {
    this._cache[key] = value
  },
}
