const loader = document.createElement('div')
loader.className = 'c-loader'

/**
 * Initiates a loader div on a specific node. A loader div is appended to the
 * node and is styled as an overlay.
 */
export default class Loader {
  /**
   * Create a loader on a specific element.
   * @param {Element} [node] The node where the loader should be appended to
   */
  constructor(node) {
    this.el = loader.cloneNode()
    this.parent = node
    this.revertPosition = false
  }

  /**
   * Attaches the loader div to a node. This overrides the parent.
   * @param {Element} [node] The node where the loader should be appended to
   */
  attach(node) {
    if (node)
      this.parent = node

    if (getComputedStyle(this.parent).position === 'static') {
      this.parent.style.position = 'relative'
      this.revertPosition = true
    }

    this.parent.appendChild(this.el)
  }

  /**
   * Removes the loader div from its parent.
   */
  detach() {
    this.parent.removeChild(this.el)

    if (this.revertPosition) {
      this.parent.style.position = ''
      this.revertPosition = false
    }
  }
}
