import Flickity from 'flickity'

const PRODUCT_CAROUSEL_SELECTOR = `[data-js-product-carousel]`

let groupCells = 1

if (matchMedia('(min-width: 768px)').matches) {
  groupCells = 2
}

if (matchMedia('(min-width: 992px)').matches) {
  groupCells = 3
}

export function init(root = document) {
  const carousels = [...root.querySelectorAll(PRODUCT_CAROUSEL_SELECTOR)]

  carousels.forEach(node => {
    const carousel = new Flickity(node, {
      arrowShape: 'M58.4545455 17 25 50.5 58.4545455 84 71 71.4375 50.0909091 50.5 71 29.5625z',
      cellAlign: 'left',
      cellSelector: '.c-product-carousel__cell',
      contain: true,
      groupCells: groupCells,
      pageDots: false,
      prevNextButtons: node.children.length > groupCells,
    })
  })
}

init()
