import { init as initDropdowns } from '../dropdown/dropdown'
import { init as initFilters } from '../filters/filters'

import Loader from '../loader/loader'
import QueryCache from '../../_app/scripts/query-cache'

/**
 * Pagination
 *
 * @param {url} data-js-pagination URL to request to.
 *
 * Pagination looks for a data-js-pagination attribute on any DOM node. The
 * attribute should contain an URL which points to an API which returns a new
 * HTML string.
 *
 * @example
 * <a data-js-pagination="/api/products/p1">
 *   1
 * </a>
 */
const ATTR_PAGINATION = 'data-js-pagination'
const SELECTOR = `[${ ATTR_PAGINATION }]`

// We do not know the target beforehand, so the loader gets no default parent.
const paginationLoader = new Loader()

/**
 * Updates the HTML of target node.
 * @param {Element} target The target node
 * @param {String} html The new HTML
 */
const updateView = (target, html) => {
  target.innerHTML = html

  // Because the newly loaded HTML does not have any events attached, we need to
  // init these modules to provide the proper JS hooks.
  init(target)
  initDropdowns(target)
  initFilters(target)
}

/**
 * Given an url, loads a new list of products to a target node
 * @param {Element} target The target node
 * @param {String} url The url for the request
 */
const loadProducts = (target, url) => {
  paginationLoader.attach(target)

  // We reuse the result from cache if the query has already been sent out
  if (QueryCache.has(url)) {
    paginationLoader.detach()
    updateView(target, QueryCache.get(url))
    return
  }

  return fetch(url)
    .then(response => response.text())
    .then(text => {

      // Whenever we have a new request, we add it's result to the queryCache
      QueryCache.set(url, text)
      paginationLoader.detach()
      updateView(target, text)
    })
}

/**
 * Initiates pagination logic for async requests.
 * @param {Element} root The root node onto which we want to override event handlers
 */
export function init(root = document) {
  const paginationLinks = [...root.querySelectorAll(SELECTOR)]

  paginationLinks.forEach(link => {
    const url = link.getAttribute(ATTR_PAGINATION)
    const target = document.querySelector(link.getAttribute('aria-controls'))

    link.addEventListener('click', event => {
      event.preventDefault()

      loadProducts(target, url)
    })
  })
}

init()
