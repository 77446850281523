const SELECTOR = `[data-js-hero]`
const SELECTOR_CONTROL = `[data-js-hero-control]`
const SELECTOR_TARGET = `[data-js-hero-target]`

function init(root = document) {
  const heroes = [...root.querySelectorAll(SELECTOR)]

  heroes.forEach(hero => {
    const control = hero.querySelector(SELECTOR_CONTROL)
    const target = hero.querySelector(SELECTOR_TARGET)

    if (control && target) {
      let active = false

      control.addEventListener('click', event => {
        event.preventDefault()

        active = !active

        control.setAttribute('aria-pressed', active)
        target.setAttribute('aria-expanded', active)
      })
    }
  })
}

// background video init
function initYoutubeBackgroundVideo() {
  // detect device type
  var isTouchDevice = /Windows Phone/.test(navigator.userAgent) || ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch;
  var isSmallScreen = jQuery(window).width() < 768;

  if (isTouchDevice || isSmallScreen) return;

  jQuery('.video-inner').each(function() {
    jQuery(this).YTPlayer();
  });
}


init();
initYoutubeBackgroundVideo();