import delay from 'lodash/delay'

const NOTIFICATION_ATTR = `data-js-notifications`
const NOTIFICATION_SELECTOR = `[${NOTIFICATION_ATTR}]`
const NOTIFICATION_WAIT = 6000

const notificationBus = document.querySelector(NOTIFICATION_SELECTOR)

if (notificationBus) {
  ;[...notificationBus.children].forEach((notification, i) => {
    notification.style.animationDelay = (5 + i) + 's'

    delay(() => {
      notificationBus.removeChild(notification)
    }, NOTIFICATION_WAIT + (i * 1000))
  })
}

export const notify = (message, type = 'info') => {
  const notification = document.createElement('div')
  const notificationContainer = document.createElement('div')

  notification.className = `o-notification o-notification--${type}`
  notificationContainer.className = `c-site-notification__container`
  notificationContainer.textContent = message

  notification.appendChild(notificationContainer)
  notificationBus.appendChild(notification)

  delay(() => notificationBus.removeChild(notification), NOTIFICATION_WAIT)
}
