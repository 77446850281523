import jump from 'jump.js'

const SMOOTH_SCROLL_ATTR = `data-js-smooth-scroll`

const SITE_HEADER_SELECTOR = `.c-site-header`
const SMOOTH_SCROLL_SELECTOR = `[data-js-smooth-scroll]`

const defaultOffset = document.querySelector(SITE_HEADER_SELECTOR).offsetHeight
const scrollNodes = [...document.querySelectorAll(SMOOTH_SCROLL_SELECTOR)]

scrollNodes.forEach(node => {
  const optionsString = node.getAttribute(SMOOTH_SCROLL_ATTR)
  const options = optionsString ? JSON.parse(optionsString) : {}
  const target = node.getAttribute('aria-controls') || document.querySelector(node.getAttribute('href'))

  target && node.addEventListener('click', event => {
    event.preventDefault()

    jump(target, {
      duration: distance => Math.abs(distance),
      offset: (defaultOffset + (options.offset || 0)) * -1
    })
  })
})
