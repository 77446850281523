import debounce from 'lodash/debounce'
import defer from 'lodash/defer'

import './menu/menu'

const BODY_FIXED_RE = /\s*?u-body-fixed\s*?/g
const CONTROL_SELECTOR = `[aria-controls^="#sub-navigation"]`
const TOGGLE_SELECTOR = `[aria-controls="#site-navigation"]`

const root = document.body

function toggleBodyFixed(active) {
  if (active) root.className += ' u-body-fixed'
  else root.className = root.className.replace(BODY_FIXED_RE, '')
}

function initNavigationMobile() {
  const navToggle = document.querySelector(TOGGLE_SELECTOR)
  const navMenu = document.querySelector(navToggle.getAttribute('aria-controls'))

  let active = false

  navToggle.addEventListener('click', event => {
    active = !active

    navToggle.setAttribute('aria-pressed', active)
    navMenu.setAttribute('aria-expanded', active)

    toggleBodyFixed(active)
  })
}

function initNavigationDesktop() {
  root.className = root.className.replace(BODY_FIXED_RE, '')

  const navControls = [...document.querySelectorAll(CONTROL_SELECTOR)]

  navControls.forEach(control => {
    const target = document.querySelector(control.getAttribute('aria-controls'))

    let active = false

    function toggleOnce(event) {
      // if event.target = select, the following is also true
      if (!control.contains(event.target) && !target.contains(event.target)) {
        window.removeEventListener('click', toggleOnce)

        // Don't want to pass the event here since it will preventDefault()
        toggle()
      }
    }

    function toggle(event) {
      if (event) event.preventDefault()

      active = !active

      control.setAttribute('aria-pressed', active)
      target.setAttribute('aria-expanded', active)

      if (window.matchMedia('(max-height: 767px)').matches) {
        toggleBodyFixed(active)
      }

      // When the user clicks outside the target, the target should close.
      // This makes sure that when the target is opened, the listener for the
      // 'outside click' is removed again after the target closes.
      defer(() => {
        if (active)
          window.addEventListener('click', toggleOnce)
        else
          window.removeEventListener('click', toggleOnce)
      })
    }

    control.addEventListener('click', toggle)
  })
}

/*

DEPRECATED

*/

const NAVIGATION_SLOT_ATTR = `data-js-navigation-slot`
const NAVIGATION_TEMPLATE_ATTR = `data-js-navigation-template`
const NAVIGATION_TEMPLATE_SELECTOR = `[${NAVIGATION_TEMPLATE_ATTR}]`

const MENU_BRANDS_WRAPPER_CLASS = 'c-site-navigation__brands'
const MENU_BRANDS_CONTAINER_CLASS = 'c-site-navigation__brands-container'

const findSlots = menuName => [...document.querySelectorAll(`[${NAVIGATION_SLOT_ATTR}="${menuName}"]`)]

const menuWrapper = document.createElement('div')
const menuContainer = menuWrapper.cloneNode()

menuWrapper.className = MENU_BRANDS_WRAPPER_CLASS
menuContainer.className = MENU_BRANDS_CONTAINER_CLASS
menuWrapper.appendChild(menuContainer)

let menuCache = []
let isMenuDesktop = false

function initNavigationBrandsMobile() {
  if (isMenuDesktop) {
    const menuWrappers = [...document.querySelectorAll(`.${MENU_BRANDS_WRAPPER_CLASS}`)]

    menuWrappers.forEach(wrapper => wrapper.parentNode.removeChild(wrapper))
    menuCache.forEach(template => template.parentNode.appendChild(template.template))

    menuCache = []
    isMenuDesktop = false
  }

}

function initNavigationBrandsDesktop() {
  if (!isMenuDesktop) {
    const navigationTemplates = [...document.querySelectorAll(NAVIGATION_TEMPLATE_SELECTOR)]

    navigationTemplates.forEach(template => {
      const menuName = template.getAttribute(NAVIGATION_TEMPLATE_ATTR)

      menuCache.push({ parentNode: template.parentNode, template: template.cloneNode(true) })

      const slots = findSlots(menuName)

      // remove default container
      // const container = template.querySelector('.c-site-navigation__item-container')
      // const menuList = container.children[0]

      // container.parentNode.appendChild(menuList)
      // container.parentNode.removeChild(container)

      const menuHTML = template.innerHTML

      template.parentNode.removeChild(template)

      slots.forEach(slot => {
        const wrapper = menuWrapper.cloneNode(true)

        wrapper.children[0].innerHTML = menuHTML
        slot.appendChild(wrapper)
      })
    })

    isMenuDesktop = true
  }
}

/*

/DEPRECATED

*/

const init = debounce(event => {
  if (window.outerWidth < 1200) {
    initNavigationMobile()
    // initNavigationBrandsMobile()
  } else {
    initNavigationDesktop()
    // initNavigationBrandsDesktop()
  }
}, 150)

window.addEventListener('resize', init)

init();

// masonry menu
function resizeGridItem(item){
  const grid = document.getElementsByClassName("c-site-navigation__menu--masonry")[0];
  const rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));
  const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap'));
  const rowSpan = Math.ceil((item.querySelector('.c-site-navigation__menu-content').getBoundingClientRect().height+rowGap)/(rowHeight+rowGap));
  item.style.gridRowEnd = "span "+rowSpan;
}

function resizeAllGridItems() {
  if (window.innerWidth >= 1200) {
    const allItems = document.querySelectorAll(".c-site-navigation__menu--masonry > li");
    for (let x = 0; x < allItems.length; x++) {
      resizeGridItem(allItems[x]);
    }
  }
}

function resizeInstance(instance){
  const item = instance.elements[0];
  resizeGridItem(item);
}

window.onload = resizeAllGridItems();
window.addEventListener("resize", resizeAllGridItems);