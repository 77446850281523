// Include generic scripts, utils, helpers, etc.
// import '../common/main';

// Site-specific scripts
import './_app/app';
import './_components/components';
import './_content-blocks/content-blocks'

// Pages
import './search/search'

// Log lb hostname
console.log('Welcome to Van Herwerden');
