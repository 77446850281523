const TABS_SELECTOR = `[data-js-tabs]`
const TABS_CONTROL_SELECTOR = `[data-js-tabs-control]`

export function init(root = document) {
  const tabComponents = [...root.querySelectorAll(TABS_SELECTOR)]

  console.log(tabComponents)

  tabComponents.forEach(tabComponent => {
    const controls = [...tabComponent.querySelectorAll(TABS_CONTROL_SELECTOR)]
    const targets = controls.map(control => root.querySelector(control.getAttribute('aria-controls')))

    controls.forEach(control => {
      const isPressed = control.getAttribute('aria-pressed') === 'true'
      const target = root.querySelector(control.getAttribute('aria-controls'))

      if (isPressed) {
        target.setAttribute('aria-expanded', true)
      }

      control.addEventListener('click', event => {
        controls
          .filter(node => node !== control)
          .forEach(node => node && node.setAttribute('aria-pressed', false))

        targets
          .filter(node => node !== target)
          .forEach(node => node && node.setAttribute('aria-expanded', false))

        control && control.setAttribute('aria-pressed', true)
        target && target.setAttribute('aria-expanded', true)
      })
    })
  })
}

init()
