/**
 * Select
 *
 * @param {String} data-js-select - The select root node with settings
 * @param {-} data-js-select-control - The button which toggles the select
 * @param {-} data-js-select-options - The list of possible options in the select
 *
 * Selects expect a data-js-select attribute on its root node, a
 * data-js-select-control on the button which opens the select, and a
 * data-js-select-options on the list of links, inputs, etc.
 *
 * A list of settings can be passed to the root selector data-js-select
 * @see settings below
 *
 * @example
 * <div data-js-select>
 *   <button data-js-select-control>
 *     Open/Close
 *   </button>
 *
 *   <ul data-js-select-options>
 *     ...
 *   </ul>
 * </div>
 */
import defer from 'lodash/defer'

const ATTR_SELECT = `data-js-select`
const SELECTOR = `[${ ATTR_SELECT }]`
const SELECTOR_CONTROL = `[data-js-select-control]`
const SELECTOR_VALUE = `[data-js-select-value]`
const SELECTOR_OPTIONS = `[data-js-select-options]`

/**
 * @typedef {Object} settings
 * @prop {Boolean} submitForm - Whether an onChange event should submit the parent form.
 *
 * Settings can be passed as a string with key:value pairs, delimited with spaces
 *
 * @example
 * <div data-js-select="submitForm:true foo:bar">
 *   ...
 * </div>
 */
const defaultSettings = {
  submitForm: false,
}

/**
 * Initiates select logic
 * @param {Element} root The root node onto which we want to override event handlers
 */
export function init(root = document) {
  const selects = [...root.querySelectorAll(SELECTOR)]

  selects.forEach(select => {
    const control = select.querySelector(SELECTOR_CONTROL)
    const value = select.querySelector(SELECTOR_VALUE)
    const options = select.querySelector(SELECTOR_OPTIONS)

    const settings = Object.assign(
      {},
      defaultSettings,
      select.getAttribute(ATTR_SELECT)
        .split(' ')
        .reduce((result, current) => {
          const [key, value] = current.split(':')
          result[key] = value

          return result
        }, {})
    )

    let active = false

    function toggleOnce(event) {
      // if event.target = select, the following is also true
      if (!select.contains(event.target)) {
        window.removeEventListener('click', toggleOnce)

        // Don't want to pass the event here since it will preventDefault()
        toggle()
      }
    }

    function toggle(event) {
      if (event) event.preventDefault()

      active = !active

      control.setAttribute('aria-pressed', active)
      options.setAttribute('aria-expanded', active)

      // When the user clicks outside the select, the select should close.
      // This makes sure that when the select is opened, the listener for the
      // 'outside click' is removed again after the select closes.
      defer(() => {
        if (active)
          window.addEventListener('click', toggleOnce)
        else
          window.removeEventListener('click', toggleOnce)
      })
    }

    control.addEventListener('click', toggle)

    // Click events on labels are fired twice:
    //  - once with the label as an event.target
    //  - twice through a synthetic event which has the input as event.target
    options.addEventListener('click', event => {
      if (event.target.nodeName === 'IMG') {
        value.textContent = event.target.id;

        // We need to push the form submit to the end of the call stack
        // because the value of the input has not been properly set yet
        // before the click event has been handled.
        if (settings.submitForm === '1' && event.target.form) {
          defer(() => event.target.form.submit())
        }

      }
      if (event.target.nodeName === 'LABEL') {
        value.textContent = event.target.textContent

        // We need to push the form submit to the end of the call stack
        // because the value of the input has not been properly set yet
        // before the click event has been handled.
        if (settings.submitForm === '1' && event.target.form) {
          defer(() => event.target.form.submit())
        }

        // Don't want to pass the event here since it will preventDefault()
        toggle()
      }
    })
  })
}

init()
