import Flickity from 'flickity-imagesloaded'

const CAROUSEL_SELECTOR = `[data-js-carousel]`

const carousels = [...document.querySelectorAll(CAROUSEL_SELECTOR)]

carousels.forEach(node => {
  const carousel = new Flickity(node, {
    arrowShape: 'M58.4545455 17 25 50.5 58.4545455 84 71 71.4375 50.0909091 50.5 71 29.5625z',
    cellAlign: 'right',
    cellSelector: '.c-carousel__cell',
    imagesLoaded: true,
    pageDots: false,
    wrapAround: true,
  })
})
