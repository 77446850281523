import Flickity from 'flickity-as-nav-for'

const VARIANT_CAROUSEL_SELECTOR = `[data-js-variant-carousel]`
const VARIANT_CAROUSEL_VIEW_SELECTOR = `[data-js-variant-carousel-view]`
const VARIANT_CAROUSEL_CONTROL_SELECTOR = `[data-js-variant-carousel-control]`

export function init(root = document) {
  const carousels = [...root.querySelectorAll(VARIANT_CAROUSEL_SELECTOR)]

  carousels.forEach(carousel => {
    const carouselView = carousel.querySelector(VARIANT_CAROUSEL_VIEW_SELECTOR)
    const carouselControls = carousel.querySelector(VARIANT_CAROUSEL_CONTROL_SELECTOR)

    const view = new Flickity(carouselView, {
      arrowShape: 'M58.4545455 17 25 50.5 58.4545455 84 71 71.4375 50.0909091 50.5 71 29.5625z',
      cellSelector: '.c-variant-carousel__cell',
      pageDots: false,
    })

    if (carouselControls) {
      const control = new Flickity(carouselControls, {
        arrowShape: 'M58.4545455 17 25 50.5 58.4545455 84 71 71.4375 50.0909091 50.5 71 29.5625z',
        asNavFor: carouselView,
        cellAlign: 'left',
        cellSelector: '.c-variant-carousel__cell',
        pageDots: false,
        prevNextButtons: false
      })
    }
  })
}

init()
