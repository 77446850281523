// const MENU_SELECTOR = `[data-js-site-navigation-control]`

// const menuControls = [...document.querySelectorAll(MENU_SELECTOR)]
// const menuTargets = menuControls.map(
//   control => document.querySelector(control.getAttribute('aria-controls'))
// )

// menuControls.forEach(control => {
//   const target = document.querySelector(control.getAttribute('aria-controls'))
//   let active

//   control.addEventListener('click', event => {
//     event.preventDefault()

//     active = control.getAttribute('aria-pressed') === 'true'
//     active = !active

//     menuControls
//       .filter(node => node !== control)
//       .forEach(node => node && node.setAttribute('aria-pressed', false))

//     menuTargets
//       .filter(node => node !== target)
//       .forEach(node => {
//         if (node) {
//           resetMenuItemHeight(node)
//           node.setAttribute('aria-expanded', false)
//         }
//       })

//     if (active) {
//       setMenuItemHeight(target)
//     } else {
//       resetMenuItemHeight(target)
//     }

//     control.setAttribute('aria-pressed', active)
//     target.setAttribute('aria-expanded', active)
//   })
// })

// const heightMap = []

// function setMenuItemHeight(target) {
//   const menuItem = target.parentNode
//   const heightMapItem = { menuItem, height: menuItem.offsetHeight }

//   if (!heightMap.filter(item => item.menuItem === menuItem).length) {
//     heightMap.push(heightMapItem)
//   }

//   let height = heightMapItem.height + target.offsetHeight

//   menuItem.style.height = `${height}px`
// }

// function resetMenuItemHeight(target) {
//   const menuItem = target.parentNode
//   const heightMapItem = heightMap.filter(item => item.menuItem === menuItem)[0]

//   if (heightMapItem)
//     menuItem.style.height = `${heightMapItem.height}px`
// }

// Click on menuitem to show submenu
$(".c-site-navigation__menu-title").on("click",function(evt){
    if ($( window ).width() < 768) {
        //if submenu is not visible, open it, otherwise do nothing and open link
        if (!$(this).hasClass("shown")) {
            evt.preventDefault();
            evt.stopPropagation();
            $(this).parent().find(".c-site-navigation__submenu").removeClass("d-none d-md-flex");
            $(this).toggleClass("shown");
        }
    }
});

// Toggle submenu by arrow on end mobile menu
$(".open-submenu").on("click",function(evt){
    if ($( window ).width() < 768) {
        evt.preventDefault();
        evt.stopPropagation();
        //toggle submenu
        if ($(this).parent().hasClass("shown")) {
            $(this).parent().parent().find(".c-site-navigation__submenu").addClass("d-none d-md-flex");
        } else {
            $(this).parent().parent().find(".c-site-navigation__submenu").removeClass("d-none d-md-flex");
        }
        $(this).parent().toggleClass("shown");
    }
});
